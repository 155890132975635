export default ({ breakpoints }) => ({
    fixtures: {
        backgroundColor: '#F8FCFF',
    },
    list: {
        listStyleType: 'none',
        margin: 0,
        padding: 0,
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',

        '& .Fixture': {
            width: 'calc(100% - 4em)',

            [breakpoints.sm]: {
                width: 'calc(50% - 4em)',
            },

            [breakpoints.lg]: {
                width: 'calc(33.333% - 4em)',
            },
        },
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '80em',
        maxWidth: 'calc(100% - 2em)',
        margin: '0 auto',
        padding: '1em',

        [breakpoints.sm]: {
            flexDirection: 'row',
            maxWidth: 'calc(100% - 3em)',
            padding: '2em 1.5em',
        },
    },
    introduction: {
        flexGrow: 0,
        flexShrink: 0,
        paddingRight: '1em',

        [breakpoints.sm]: {
            width: '25%',
        },
    },
    content: {
        [breakpoints.sm]: {
            width: '75%',
        },
    },
    title: {
        color: '#444',
        fontSize: '2em',
        marginBottom: '0.25em',
    },
    body: {
        color: '#777',
    },
    buttons: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1em',
    },
    loadButton: {
        display: 'inline-block',
        fontFamily: 'inherit',
        backgroundColor: '#218CC7',
        padding: '0.75em 1em',
        color: '#fff',
        borderRadius: '1em',
        fontWeight: 500,
        textDecoration: 'none',
        fontSize: '0.9em',
        border: 'none',
        margin: '0 auto',

        '&:hover': {
            cursor: 'pointer',
        },

        '&:disabled': {
            cursor: 'not-allowed',
            opacity: 0.5,
        },
    },
    none: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        maxWidth: '25em',

        [breakpoints.sm]: {
            marginTop: '4em'
        }
    }
})