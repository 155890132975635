import React, {useEffect} from 'react'
import withStyles from 'react-jss'
import styles from './League.styles'
import PropTypes from 'prop-types'
import {getLeague} from "../../api/league";
import {useQuery} from "react-query";

const League = ({ classes }) => {
    useEffect(() => {
        const script = document.createElement('script')
        script.src = 'https://fulltime-league.thefa.com/client/api/cs1.js?v2'
        script.async = true
        window.lrcode = '726287741'
        document.body.appendChild(script);
    }, [])

    const { isLoading, data } = useQuery({ queryFn: getLeague })

    return <div className={classes.league}>
        <div className={classes.container}>
            <div className={classes.introduction}>
                <h2 id="league" className={classes.title}>League Table</h2>
                <p className={classes.body}>
                    Check in on how Caton United are doing in the <strong>West Lancashire Football League – Division 2</strong>.
                </p>
            </div>
            <div className={classes.content}>
                {!isLoading && <table cellPadding={0} cellSpacing={0} className={classes.table}>
                    <thead>
                    <tr>
                        <th>Pos</th>
                        <th>Team</th>
                        <th>P</th>
                        <th>W</th>
                        <th>D</th>
                        <th>L</th>
                        <th>GD</th>
                        <th>Pts</th>
                    </tr>

                    </thead>
                    <tbody>
                    {data.map((team) => <tr key={team.teamName} className={team.teamName === "Caton United" ? classes.caton : undefined}>
                        <td>{team.position}</td>
                        <td>{team.teamName}</td>
                        <td>{team.gamesPlayed}</td>
                        <td>{team.wins}</td>
                        <td>{team.draws}</td>
                        <td>{team.losses}</td>
                        <td>{team.goalDifference}</td>
                        <td>{team.points}</td>
                    </tr>)}
                    </tbody>
                </table>}
            </div>
        </div>
    </div>
}

League.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(League)