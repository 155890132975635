import React from 'react'
import { Hero, Introduction, Articles, Fixtures, ContactForm, League, Results } from '../../components'
import { useQuery, gql } from '@apollo/client'
import formatISO from 'date-fns/formatISO'
import startOfDay from 'date-fns/startOfDay'

const Home = () => {
    const articles = useQuery(gql`
        query GetArticles($offset: Int, $limit: Int, $after: String) {
            articles(orderBy: createdAt_DESC, first: $limit, skip: $offset, after: $after) {
                id
                title
                content {
                    text
                }
                updatedAt
                publishedAt
            }
            articlesConnection {
                aggregate {
                    count
                }
            }
        }
    `, { variables: { offset: 0, limit: 3 } })

    const fixtures = useQuery(gql`
        query GetFixtures($offset: Int, $limit: Int, $after: String, $date: DateTime) {
            fixturesConnection(orderBy: date_ASC, first: $limit, skip: $offset, after: $after, where: { date_gte: $date}) {
                edges {
                    node {
                        id
                        homeTeam
                        awayTeam
                        location
                        date
                        homeMatch
                        homeScore
                        awayScore
                    }
                }
                pageInfo {
                    hasNextPage
                }
            }
        }
    `, {
        variables: {
            offset: 0, limit: 3, date: formatISO(startOfDay(new Date())),
        },
    })

    const results = useQuery(gql`
        query GetResults($offset: Int, $limit: Int, $after: String, $date: DateTime) {
            fixturesConnection(orderBy: date_DESC, first: $limit, skip: $offset, after: $after, where: { date_lte: $date, homeScore_gte: 0 }) {
                edges {
                    node {
                        id
                        homeTeam
                        awayTeam
                        location
                        date
                        homeMatch
                        homeScore
                        awayScore
                    }
                }
                pageInfo {
                    hasNextPage
                }
            }
        }
    `, {
        variables: {
            offset: 0, limit: 3, date: formatISO(startOfDay((new Date()))),
        },
    })

    return <>
        <Hero />
        <Introduction />
        <Fixtures
            fixtures={fixtures.data?.fixturesConnection.edges.map(edge => edge.node)}
            loading={fixtures.loading} error={fixtures.error}
            fetchMore={fixtures.fetchMore}
            hasNextPage={fixtures.data?.fixturesConnection.pageInfo.hasNextPage} />
        <Results
            fixtures={results.data?.fixturesConnection.edges.map(edge => edge.node)}
            loading={results.loading} error={results.error}
            fetchMore={results.fetchMore}
            hasNextPage={results.data?.fixturesConnection.pageInfo.hasNextPage} />
        <League />
        <Articles
            articles={articles.data?.articles} loading={articles.loading} error={articles.error}
            fetchMore={articles.fetchMore}
            total={articles.data?.articlesConnection.aggregate.count} />
        <ContactForm />
    </>
}

export default Home